import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgGrowthArrow = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 25 22" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.76416 0.158447H19.471C21.1278 0.158447 22.471 1.50159 22.471 3.15845V16.6233C22.471 18.2802 21.1278 19.6233 19.471 19.6233H3.76416C2.10731 19.6233 0.76416 18.2802 0.76416 16.6233V3.15845C0.76416 1.50159 2.10731 0.158447 3.76416 0.158447ZM17.9544 4.89854L17.845 3.82607L16.7838 4.01557L13.4529 4.61036L12.4684 4.78615L12.82 6.755L13.8044 6.57922L14.6199 6.4336L11.2965 10.8232L9.04577 8.84266L8.2552 8.14699L7.6013 8.97245L3.21614 14.5082L2.5952 15.292L4.16292 16.5339L4.78386 15.75L8.51512 11.0398L10.7835 13.0358L11.5916 13.7469L12.2413 12.8887L16.2226 7.63009L16.3046 8.43435L16.4061 9.42919L18.3958 9.22627L18.2943 8.23143L17.9544 4.89854Z"
      fill={props.color}
    />
  </Svg>
);
export default SvgGrowthArrow;
