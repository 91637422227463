import * as React from "react";

import Svg, { SvgProps, Path, G } from "react-native-svg";

const SvgGithub = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <G fill={props.color}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.417c-2.374 0-4.67.842-6.478 2.372a9.931 9.931 0 0 0-3.389 5.98 9.898 9.898 0 0 0 1.314 6.738 9.99 9.99 0 0 0 5.39 4.287c.497.092.684-.216.684-.477 0-.262-.01-1.021-.013-1.851-2.782.601-3.37-1.174-3.37-1.174-.453-1.152-1.11-1.455-1.11-1.455-.907-.616.069-.605.069-.605 1.005.071 1.533 1.026 1.533 1.026.891 1.52 2.34 1.08 2.91.823.089-.643.349-1.081.635-1.33-2.222-.25-4.557-1.103-4.557-4.913a3.839 3.839 0 0 1 1.03-2.67c-.102-.25-.445-1.26.098-2.633 0 0 .84-.267 2.75 1.019a9.544 9.544 0 0 1 5.008 0c1.91-1.286 2.747-1.02 2.747-1.02.545 1.37.202 2.381.1 2.635a3.83 3.83 0 0 1 1.031 2.672c0 3.818-2.34 4.66-4.565 4.905.357.31.677.914.677 1.842 0 1.33-.012 2.401-.012 2.729 0 .265.18.574.688.477a9.99 9.99 0 0 0 5.39-4.288 9.898 9.898 0 0 0 1.314-6.74 9.932 9.932 0 0 0-3.391-5.979 10.036 10.036 0 0 0-6.48-2.37H12Z"
      />
      <Path d="M5.787 16.693c-.021.05-.101.064-.166.03-.064-.035-.112-.099-.089-.15.023-.05.101-.064.166-.03.064.035.114.101.089.15ZM6.193 17.143a.166.166 0 0 1-.206-.046c-.064-.07-.077-.165-.028-.208.05-.043.14-.023.204.046.065.07.08.165.03.208ZM6.587 17.714c-.061.043-.166 0-.224-.085a.163.163 0 0 1 0-.238c.062-.04.166 0 .224.084.058.084.06.196 0 .24ZM7.122 18.267c-.055.061-.166.045-.257-.037-.091-.083-.113-.195-.058-.254.055-.06.166-.043.26.038.094.08.113.194.055.253ZM7.872 18.59c-.025.078-.138.112-.25.08-.113-.034-.187-.126-.166-.205.022-.08.136-.115.25-.08.114.037.187.124.166.205ZM8.69 18.646c0 .08-.093.15-.212.151-.12.002-.217-.064-.217-.144 0-.081.093-.15.212-.152.12-.002.217.063.217.145ZM9.452 18.52c.015.08-.068.164-.188.184-.119.02-.223-.028-.238-.107-.015-.08.071-.165.187-.186.116-.022.224.028.239.108Z" />
    </G>
  </Svg>
);

export default SvgGithub;
