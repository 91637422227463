import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgCreatorChannelType = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <Path
      fill={props.color}
      d="M10.272 5.911a1.38 1.38 0 0 1-.595.693l-7.707 4.45a2.346 2.346 0 0 0-.859 3.203l2.346 4.063a2.346 2.346 0 0 0 3.204.858 1.173 1.173 0 0 1 1.602.43l1.76 3.046a1.173 1.173 0 0 0 1.601.429l2.032-1.173a1.173 1.173 0 0 0 .43-1.602L12.43 17.44a1.38 1.38 0 0 1 .504-1.884l1.434-.828a1.38 1.38 0 0 1 .897-.169l4.109.626c1.143.174 1.98-1.052 1.402-2.054L14.265 1.853c-.579-1.002-2.059-.89-2.48.187l-1.513 3.871Zm6.042 4.184c.518.897-.231 1.995-1.255 1.839a1.236 1.236 0 0 0-.804.151l-7.572 4.372a1.38 1.38 0 0 1-1.884-.505l-.966-1.673a1.38 1.38 0 0 1 .505-1.884l7.571-4.372c.243-.14.431-.359.533-.62.378-.965 1.703-1.065 2.221-.168l1.651 2.86Zm5.777-5.242a5.213 5.213 0 0 1 .628 3.513c-.176 1-1.41.996-1.918.116l-2.488-4.31c-.51-.883.11-1.957 1.067-1.603a5.211 5.211 0 0 1 2.711 2.284Z"
    />
    <Path
      fill={props.color}
      d="M20.189 13.827 13.083 3.88l-.947 3.316-9 4.263-.947 2.368 3.79 4.737 9-4.737h5.21Z"
    />
  </Svg>
);
export default SvgCreatorChannelType;
