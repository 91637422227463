import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgSettings = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.421 3.24a.818.818 0 0 1 1.397.578V3.904a2.5 2.5 0 0 0 1.51 2.286 2.5 2.5 0 0 0 2.753-.502l.063-.063a.817.817 0 0 1 1.158 0 .818.818 0 0 1 0 1.159l-.055.054-.007.008a2.5 2.5 0 0 0-.576 2.566 1 1 0 0 0 .071.255 2.5 2.5 0 0 0 2.288 1.515h.159a.818.818 0 0 1 0 1.636H20.096a2.5 2.5 0 0 0-2.286 1.51 2.5 2.5 0 0 0 .502 2.753l.063.063a.817.817 0 0 1 0 1.158.82.82 0 0 1-1.159 0l-.054-.055-.008-.007a2.5 2.5 0 0 0-2.753-.502 2.5 2.5 0 0 0-1.51 2.285v.159a.819.819 0 0 1-1.636 0V20.077A2.5 2.5 0 0 0 9.646 17.8a2.5 2.5 0 0 0-2.728.512l-.063.063a.818.818 0 0 1-1.158 0 .818.818 0 0 1 0-1.159l.055-.054.007-.008a2.5 2.5 0 0 0 .502-2.753 2.5 2.5 0 0 0-2.285-1.51h-.159a.818.818 0 0 1 0-1.636H3.923A2.5 2.5 0 0 0 6.2 9.647a2.5 2.5 0 0 0-.512-2.73l-.063-.062a.818.818 0 0 1 0-1.158.818.818 0 0 1 1.159 0l.054.055.008.007a2.5 2.5 0 0 0 2.566.576 1 1 0 0 0 .255-.071 2.5 2.5 0 0 0 1.515-2.288v-.159c0-.217.086-.425.24-.578Zm8.221 11.891-.915-.404.92.394a.5.5 0 0 1 .456-.303h.079a2.818 2.818 0 1 0 0-5.636h-.152a.5.5 0 0 1-.4-.206.994.994 0 0 0-.06-.18.5.5 0 0 1 .097-.548l.05-.05A2.818 2.818 0 0 0 16.643 3.6a2.82 2.82 0 0 0-.915.611l-.05.05a.5.5 0 0 1-.548.097l-.01-.004a.5.5 0 0 1-.303-.457v-.079a2.818 2.818 0 1 0-5.636 0v.152a.5.5 0 0 1-.206.4 1 1 0 0 0-.18.06.5.5 0 0 1-.548-.097l-.05-.05a2.818 2.818 0 1 0-3.987 3.988l.05.05a.5.5 0 0 1 .097.548c-.009.02-.017.039-.024.059a.5.5 0 0 1-.449.327h-.067a2.818 2.818 0 1 0 0 5.636h.152a.5.5 0 0 1 .456.303l.005.01a.5.5 0 0 1-.098.548l-.05.05a2.817 2.817 0 0 0 .915 4.598 2.817 2.817 0 0 0 3.073-.611l.05-.05a.5.5 0 0 1 .548-.097c.02.009.039.017.059.024a.5.5 0 0 1 .327.449v.067a2.818 2.818 0 1 0 5.636 0v-.152a.5.5 0 0 1 .303-.456l.01-.005a.5.5 0 0 1 .548.098l.05.05a2.817 2.817 0 0 0 3.987 0l-.707-.708.707.707a2.816 2.816 0 0 0 0-3.987l-.05-.05a.5.5 0 0 1-.097-.548ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgSettings;
