import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgGoogleOriginal = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M22 12.23c0-.68-.056-1.363-.176-2.032H12.2v3.85h5.51a4.624 4.624 0 0 1-2.038 3.04v2.498h3.287C20.89 17.844 22 15.272 22 12.23Z"
      fill="#4285F4"
    />
    <Path
      d="M12.201 22c2.752 0 5.072-.886 6.763-2.414l-3.288-2.499c-.914.61-2.095.956-3.47.956-2.662 0-4.919-1.76-5.728-4.127H3.085v2.576C4.817 19.87 8.345 22 12.201 22Z"
      fill="#34A853"
    />
    <Path
      d="M6.474 13.916a5.877 5.877 0 0 1 0-3.828V7.512H3.085a9.832 9.832 0 0 0 0 8.98l3.389-2.576Z"
      fill="#FBBC04"
    />
    <Path
      d="M12.201 5.958a5.605 5.605 0 0 1 3.914 1.499l2.912-2.855a9.915 9.915 0 0 0-6.826-2.601C8.345 2 4.817 4.13 3.085 7.512l3.389 2.576c.806-2.37 3.066-4.13 5.727-4.13Z"
      fill="#EA4335"
    />
  </Svg>
);

export default SvgGoogleOriginal;
