import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgApple = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M20.493 17.586a10.88 10.88 0 0 1-1.075 1.933c-.565.807-1.028 1.365-1.385 1.674-.553.509-1.146.77-1.78.784-.456 0-1.005-.13-1.645-.392-.641-.262-1.23-.392-1.77-.392-.565 0-1.172.13-1.82.392-.65.263-1.173.4-1.574.413-.608.026-1.215-.242-1.82-.805-.386-.337-.87-.914-1.449-1.733-.62-.874-1.132-1.887-1.532-3.043C4.215 15.169 4 13.96 4 12.79c0-1.34.29-2.496.87-3.465A5.102 5.102 0 0 1 6.69 7.483a4.9 4.9 0 0 1 2.463-.695c.483 0 1.117.15 1.905.443.785.295 1.29.444 1.51.444.166 0 .726-.175 1.676-.523.898-.323 1.656-.457 2.277-.404 1.683.135 2.947.799 3.787 1.994-1.505.912-2.249 2.189-2.234 3.827.014 1.276.476 2.338 1.386 3.181.413.392.873.694 1.385.909-.11.322-.228.63-.353.927ZM16.634 2.4c0 1-.365 1.934-1.094 2.799-.879 1.027-1.942 1.621-3.095 1.527a3.112 3.112 0 0 1-.023-.379c0-.96.418-1.987 1.16-2.828.371-.425.842-.779 1.414-1.061.57-.278 1.11-.432 1.617-.458.015.134.021.267.021.4Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgApple;
