import * as React from "react";

import Svg, { SvgProps, Path } from "react-native-svg";

const SvgGlobe1 = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 20 20" fill="none" {...props}>
    <Path
      d="M10 2c-4.413 0-8 3.587-8 8 0 4.333 3.46 7.867 7.764 7.993.078.008.156.007.236.007.08 0 .157 0 .236-.007C14.539 17.867 18 14.333 18 10c0-4.413-3.587-8-8-8Zm-.452.965h.007V6H7.264c.136-.416.293-.802.465-1.146.523-1.045 1.166-1.684 1.82-1.889Zm.896 0c.656.203 1.302.84 1.826 1.89.173.343.33.73.466 1.145h-2.292V2.965Zm-2.764.313c-.28.343-.529.74-.75 1.18-.231.464-.43.986-.597 1.542H4.125a7.122 7.122 0 0 1 3.556-2.722Zm4.64 0A7.121 7.121 0 0 1 15.874 6h-2.208a9.715 9.715 0 0 0-.598-1.542 6.402 6.402 0 0 0-.75-1.18Zm-8.716 3.61h2.5a14.46 14.46 0 0 0-.312 2.667H2.903c.06-.95.306-1.854.701-2.666Zm3.417 0h2.535v2.667H6.68c.029-.953.148-1.852.34-2.666Zm3.423 0h2.535c.193.815.311 1.714.34 2.667h-2.875V6.89Zm3.452 0h2.5c.395.814.642 1.717.701 2.667h-2.889a14.463 14.463 0 0 0-.312-2.666ZM2.903 10.445h2.89c.025.941.132 1.84.312 2.667H3.598a7.105 7.105 0 0 1-.695-2.667Zm3.778 0h2.875v2.667H7.02a13.42 13.42 0 0 1-.34-2.667Zm3.764 0h2.875c-.03.953-.148 1.854-.34 2.667h-2.535v-2.667Zm3.764 0h2.889c-.059.95-.3 1.855-.695 2.667h-2.507c.18-.828.286-1.726.313-2.667ZM4.119 14h2.215a9.65 9.65 0 0 0 .597 1.542c.22.438.466.838.743 1.18A7.114 7.114 0 0 1 4.12 14Zm3.146 0h2.291v3.042c-.655-.205-1.302-.855-1.826-1.903A8.45 8.45 0 0 1 7.265 14Zm3.18 0h2.292a8.405 8.405 0 0 1-.466 1.139c-.524 1.049-1.17 1.698-1.826 1.903V14Zm3.222 0h2.215a7.113 7.113 0 0 1-3.555 2.722 6.46 6.46 0 0 0 .743-1.18c.232-.465.43-.984.597-1.542Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgGlobe1;
